@use './../abstracts';


.editorial {
  display:block;
  width: 100%;
  height: 60px;
  max-height: 60px;
  margin: 0;
  z-index:5;
  bottom:0;
  position:absolute;
  left:0px;
  position:fixed;
  //float:left;
}

.parallax1 > use {
  animation: move-forever1 10s linear infinite;
  fill: abstracts.$grey100;
  &:nth-child(1) {
    animation-delay: -2s;
  }
}
.parallax2 > use {
  animation: move-forever2 8s linear infinite;
  fill: abstracts.$grey200;
  &:nth-child(1) {
    animation-delay: -2s;
  }
}
.parallax3 > use {
  animation: move-forever3 6s linear infinite;
  fill: abstracts.$grey300;
  &:nth-child(1) {
    animation-delay: -2s;
  }
}
.parallax4 > use {
  animation: move-forever4 4s linear infinite;
  fill: abstracts.$orange-bright;
  
  &:nth-child(1) {
    animation-delay: -2s;
    margin-bottom: 1000px;
  }
}
@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}
@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@keyframes move-forever3 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}
@keyframes move-forever4 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

.waveDivider{
  //height: 100vh;
}
