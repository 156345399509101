@use "../abstracts";
@use "../elements";
@import url("https://cdn.jsdelivr.net/gh/devicons/devicon@v2.14.0/devicon.min.css");

.resume_container {
    background-color: #fff;
}

.resume {
    //position: absolute;
    padding: 0 10%;
    margin-bottom: 200px;
    //margin: 10%;
}

.resume-title {
    position: relative;
    display: flex;
    justify-content: center;
    top: 85px;
    font-family: abstracts.$arabic2;
    user-select: none;

    h1 {
        position: absolute;
    }

    &__title {
        font-size: 40px;
        color: abstracts.$white;
    }

    &__shadow {
        font-size: 80px;
        bottom: -90px;
        color: abstracts.$grey200;
        opacity: 0.3;
    }

    margin-bottom: 40px;
}

.infotech {
    font-family: abstracts.$arabic2;
    //z-index: 9999;
    position: relative;
    top: 100px;
    width: auto;
    height: auto;
    //background-color: rgb(69, 94, 94);
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    color: abstracts.$white;

    .technologies_container {
        //background-color: antiquewhite;
        height: auto;
        //width: 42vw;
        width: auto;
        padding: 60px 2vw;

        &__list-container {
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                "Helvetica Neue", sans-serif;
            //text-decoration: none;
            text-align: start;
            white-space: nowrap;
            display: grid;
            grid-template-columns: auto auto auto auto;
            justify-content: space-evenly;

            &__list-header {
                font-weight: 900;
                margin-bottom: 10px;
            }

            ul {
                list-style: none;
                //padding-left: 5%;
            }
        }
    }

    .information_container {
        //background-color:rgb(167, 132, 89);
        height: auto;
        //width: 42vw ;
        width: auto;
        padding: 60px 3vw;
        font-family: abstracts.$arabic2;
        font-size: 1.3em;
        text-align: start;

        p {
            margin-bottom: 40px;
        }
    }
}

.resume-container {
    padding: 20px;
    text-align: start;
    top: 100px;
    position: relative;
    background-color: abstracts.$grey100;
    border-inline-start: abstracts.$grey300 solid;
    color: abstracts.$white;
    font-family: abstracts.$arabic2;
    margin: 0px 60px;

    &::before {
        position: absolute;
        inset-inline-start: -14px;
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        background-color: abstracts.$grey100;
        border: 5px solid abstracts.$orange-bright;
        border-radius: 100%;
    }

    &__meta {
        display: flex;
        justify-content: space-between;
    }

    &__date {
        text-align: start;
        margin-bottom: 10px;
        border-bottom: #fff solid 2px;
    }

    &__main {
        margin-bottom: 20px;
    }

    &__tech-used {
        &__list {
            display: flex;
            justify-content: start;
            flex-wrap: wrap;

            div {
                padding: 2px 9px;
                margin: 0px 0 2px 4px;
                background-color: abstracts.$orange-dark;
                //border: 2px solid abstracts.$grey300;
                //background-color: abstracts.$orange-dark;
                //color: abstracts.$white;
                color: abstracts.$white;
                border-radius: 20px;
            }
        }
    }
}

.button {
    text-decoration: none;
    color: abstracts.$orange-bright;
    //color: abstracts.$white;
    padding: 10px 20px;
    border: abstracts.$orange-bright solid 2px;
    //background-color: abstracts.$orange-bright;
    transition-duration: 400ms;
    width: fit-content;
    border-radius: 30px;
    //display: flex;

    a {
        text-decoration: none;
        vertical-align: middle;
    }

    &:hover {
        background-color: abstracts.$orange-dark;
        color: abstracts.$white;
        transition-duration: 400ms;
    }

    .icon {
        //margin-top: 2px;
        vertical-align: middle;
    }
}

@media (max-width: 1500px) {
    .infotech {
        //text-align: right;
        grid-template-columns: auto;
    }

    @media (max-width: 700px) {
        .resume-container {
            //top: 480px;
            margin: 0px 10px;
            font-size: small;
        }

        .infotech {
            .information_container {
                //width: 100vw;
                height: auto;
                font-size: small;
                text-align: center;
            }

            .technologies_container {
                //width: 100vw;
                height: fit-content;
                padding-top: 0;

                &__list-container {
                    grid-template-columns: auto;
                    justify-content: center;

                    ul {
                        padding: 10px;
                        margin-bottom: 10px;
                        background-color: abstracts.$orange-dark;
                    }
                }
            }
        }

        .button {
            margin: auto;
        }
    }
}
