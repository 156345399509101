@use "./../abstracts";

@mixin align-h-v {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container {
  //border: #282c34 4px solid;
  width: 40vmin;
  height: 40vmin;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-logo {
  //remove highliting
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  filter: abstracts.$orange-svg;
  position: absolute;
  height: 40vmin;
  pointer-events: none;
}

.App-logo2 {
  //remove highliting
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  filter: abstracts.$orange-svg;
  position: absolute;
  height: 28vmin;
  pointer-events: none;
}

.call-to-action {
  //remove highliting
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  cursor: pointer;
  text-decoration: none;
  font-size: 3vmin;
  font-family: abstracts.$arabic;
  // font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: abstracts.$white;
  background-color: abstracts.$orange;
  border-radius: 100%;
  align-items: center;
  height: 15vmin;
  width: 15vmin;
  transition-duration: 100ms;
  @include align-h-v;

  &:hover {
    //background-color: abstracts.$orange-dark;
    transform: rotateZ(-10deg);
    transition-duration: 100ms;
    font-size: 4vmin;
    //height: 12vmin;
    //width: 12vmin;
    z-index: 9999;
  }
}

//
.App-logo4 {
  position: absolute;
  height: 8vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 80s linear;
  }

  .App-logo2 {
    animation: App-logo-spin-reverse infinite 60s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin-reverse {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}
