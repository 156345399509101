/* SCSS HEX (green color pallet)*/

$orange-svg: invert(53%) sepia(63%) saturate(480%) hue-rotate(95deg) brightness(93%) contrast(86%);
$orange-svg-grad: linear-gradient(0deg, $orange-svg );
$orange: #2EAC68;
$white: #f5fff9;

$orange-dark: #149751;
$orange-bright: #2EAC68;
$grey100: #122333;
$grey200: #233e57;
$grey300: #3c5f80;


/* SCSS HEX (orange color pallet)*/

// $orange-svg: invert(70%) sepia(66%) saturate(3317%) hue-rotate(320deg) brightness(101%) contrast(94%);
// $orange-svg-grad: linear-gradient(0deg, $orange-svg );
// $orange: #F77962;
// $white: rgb(255, 226, 209);

// $orange-dark: #f5593dff;
// $orange-bright: #f77962ff;
// $grey100: #242a38ff;
// $grey200: #383d4aff;
// $grey300: #4e586eff;


/* SCSS HSL */
$orange-soda: hsla(9, 90%, 60%, 1);
$bittersweet: hsla(9, 90%, 68%, 1);
$gunmetal: hsla(222, 22%, 18%, 1);
$charcoal: hsla(223, 14%, 25%, 1);
$independence: hsla(221, 17%, 37%, 1);

/* SCSS RGB */
$orange-soda: rgba(245, 89, 61, 1);
$bittersweet: rgba(247, 121, 98, 1);
$gunmetal: rgba(36, 42, 56, 1);
$charcoal: rgba(56, 61, 74, 1);
$independence: rgba(78, 88, 110, 1);

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #f5593dff, #f77962ff, #242a38ff, #383d4aff, #4e586eff);
$gradient-right: linear-gradient(90deg, #f5593dff, #f77962ff, #242a38ff, #383d4aff, #4e586eff);
$gradient-bottom: linear-gradient(180deg, #f5593dff, #f77962ff, #242a38ff, #383d4aff, #4e586eff);
$gradient-left: linear-gradient(270deg, #f5593dff, #f77962ff, #242a38ff, #383d4aff, #4e586eff);
$gradient-top-right: linear-gradient(45deg, #f5593dff, #f77962ff, #242a38ff, #383d4aff, #4e586eff);
$gradient-bottom-right: linear-gradient(135deg, #f5593dff, #f77962ff, #242a38ff, #383d4aff, #4e586eff);
$gradient-top-left: linear-gradient(225deg, #f5593dff, #f77962ff, #242a38ff, #383d4aff, #4e586eff);
$gradient-bottom-left: linear-gradient(315deg, #f5593dff, #f77962ff, #242a38ff, #383d4aff, #4e586eff);
$gradient-radial: radial-gradient(#f5593dff, #f77962ff, #242a38ff, #383d4aff, #4e586eff);