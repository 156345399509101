@use "./../abstracts";

.maintexts {
    //background-color: beige;
    display: inline-block;
    text-align: start;

    font-family: abstracts.$arabic2;
    color: abstracts.$white;
    //margin-left: 0;
    //margin-top: 30%;

    h4 {
        color: abstracts.$grey300;
        //font-size: 2vmin;
    }

    h1 {
        color: abstracts.$orange-bright;
        margin-bottom: 10px;
        // font-size: 3vmin;
    }
}
