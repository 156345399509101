@use '../abstracts';

@mixin name ($content, $side) {
    z-index: 3;
    display: inline;
    position: absolute;
    content: $content;
    color: abstracts.$white;
    font-family: abstracts.$arabic2;
    width: 20px;
    height: 20px;

    @if $side =='right' {
        inset-inline-end: 115px;
        // inset-inline-end: 110px;
    }

    @else if $side =='left' {
        inset-inline-start: 70px;
    }

    white-space: nowrap;
    margin-top: 20px;
    opacity: 0;
    pointer-events: none;
}




.social {
    z-index: 9999;
    position: absolute;
    height: auto;
    width: auto;
    pointer-events: all;

    .social-email {
        &::before {
            @include name('ahmedsamiredu@hotmail.com', 'left');
        }

        &:hover::before {
            opacity: 100;
            transition-delay: 200ms;
            transition-duration: 300ms;
        }
    }

    .social-phone {
        &::before {
            @include name('0503 888 536', 'left');
        }

        &:hover::before {
            opacity: 100;
            transition-delay: 100ms;
            transition-duration: 300ms;
        }
    }

}

.nav {
    position: absolute;
    height: auto;
    width: auto;
    right: 0px;
    pointer-events: all;

    .nav-intro {
        &::before {
            @include name('HOME', 'right');
            // @include name('الواجهة','right');

        }

        &:hover::before {
            opacity: 100;
            transition-delay: 100ms;
            transition-duration: 300ms;

        }



    }

    .nav-carrer {
        &::before {
            @include name('RESUME', 'right');
            // @include name('السيرة','right');
        }

        &:hover::before {
            opacity: 100;
            transition-delay: 100ms;
            transition-duration: 300ms;
        }
    }

    .nav-portfolio {
        &::before {
            @include name('PROJECTS', 'right');
            // @include name('مشاريع','right');
        }

        &:hover::before {
            opacity: 100;
            transition-delay: 100ms;
            transition-duration: 300ms;
        }
    }

    .nav-contact {
        &::before {
            @include name('CONTACT', 'right');
            // @include name('تواصل','right');
        }

        &:hover::before {
            opacity: 100;
            transition-delay: 100ms;
            transition-duration: 300ms;
        }
    }
}

/////////////////////////////////////////////////
.social>*>div {
    display: block;
    height: 40px;
    width: 40px;
    margin-bottom: 50px;
    border-radius: 100%;
    z-index: 5;
    color: abstracts.$grey300;

    &:hover {
        cursor: pointer;
        color: abstracts.$white;

        .github {
            background-color: abstracts.$orange-bright;
        }

        .linkedin {
            background-color: abstracts.$orange-bright;
        }

        .phone {
            background-color: abstracts.$orange-bright;
            z-index: 2;
            width: 150px;
            left: 10px;
            transition: width 300ms;
            display: flex;
            justify-content: left;
        }

        .email {
            background-color: abstracts.$orange-bright;
            z-index: 2;
            width: 270px;
            left: 10px;
            transition: width 300ms;
            display: flex;
            justify-content: left;
        }
    }
}

.social>*>div>span {
    display: flex;
    justify-content: left;
    padding: 10px;
    cursor: pointer;
    border: 3px solid abstracts.$orange;
    position: absolute;
    border-radius: 100px;
    width: inherit;
    height: inherit;
    left: 10px;
    transition: 300ms;
}

//////////////////////////////////////////
.nav>*>div {
    display: block;
    height: 40px;
    width: 40px;
    margin-bottom: 50px;
    border-radius: 100%;
    color: abstracts.$grey300;

    &:hover {
        cursor: pointer;

        span {
            background-color: abstracts.$orange-bright;
            z-index: 2;
            width: 120px;
            right: 10px;
            transition: width 300ms;
            display: flex;
            justify-content: right;
            cursor: pointer;

            * {
                color: abstracts.$white;
            }
        }
    }
}

.nav>*>div>span {
    z-index: 2;
    display: flex;
    justify-content: right;
    padding: 10px;
    cursor: pointer;
    border: 3px solid abstracts.$orange;
    position: absolute;
    border-radius: 100px;
    width: inherit;
    height: inherit;
    right: 10px;
    transition: 300ms;
}