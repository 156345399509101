@use "./../abstracts";

.portfolio-page {
    height: 100%;
    display: grid;

    justify-items: center;

    //grid-template-columns: auto;
}

.portfolio-title {
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    top: 70px;
    font-family: abstracts.$arabic2;
    user-select: none;

    h1 {
        position: absolute;
    }

    &__title {
        font-size: 40px;
        color: abstracts.$white;
    }

    &__shadow {
        font-size: 70px;
        bottom: -80px;
        color: abstracts.$grey200;
        opacity: 0.3;
    }

    //margin-bottom: 40px;
}

.portfolio-container {
    margin-top: 180px;
    margin-bottom: 100px;
    //position: absolute;
    //background-color: aliceblue;
    width: 70%;
    height: fit-content;
    display: grid;
    gap: 50px;
    grid-template-columns: auto;
    justify-items: center;

    @media (max-width: 520px) {
        width: 100%;
    }
}

.card {
    // background-color: abstracts.$grey200;
    // border-inline-start: 4px abstracts.$orange-bright solid;
    // color: abstracts.$white;
    // width: 70%;
    // height: 250px;
    // display: grid;

    // grid-template-columns: 50% 50%;

    // @media (max-width:1320px) {
    //     width: 100%;
    //     height: 400px;
    //     grid-template-columns: none;
    //     grid-template-rows: 60% 40%;

    // }

    background-color: abstracts.$grey200;
    border-inline-start: 4px abstracts.$orange-bright solid;
    color: abstracts.$white;
    width: 70%;
    height: 250px;
    display: flex;

    justify-content: space-between;

    &__image {
        display: block;
        width: 50%;

        //
        &__portfolio {
            width: 100%;
            height: 100%;
            background-image: url("./../../assets/portfolio_pic.png");
            background-size: cover;
            background-clip: 0 50%;
            background-repeat: no-repeat;
            background-position: center;
        }

        &__weather {
            width: 100%;
            height: 100%;
            background-image: url("./../../assets/weatherapp_pic.png");
            background-size: cover;
            background-clip: 0 50%;
            background-repeat: no-repeat;
            background-position: center;
        }

        &__coming-soon {
            width: 100%;
            height: 100%;
            background-image: url("./../../assets/coming-soon.jpg");
            background-size: cover;
            background-clip: 0 50%;
            background-repeat: no-repeat;
            background-position: center;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__info {
        width: 50%;

        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        font-family: abstracts.$arabic2;
        text-align: start;
        display: grid;
        padding: 10px;
        grid-template-rows: 25% 45% 30%;

        @media (max-width: 600px) {
            font-size: smaller;
        }

        &__title {
            //background-color: aliceblue;
        }

        &__body {
            //background-color: rgb(142, 178, 209);
        }

        &__footer {
            //background-color: rgb(22, 146, 255);
            display: grid;
            grid-template-columns: auto auto;
            align-items: center;
            justify-content: space-between;
            padding-inline-end: 50px;

            &__tech {
                display: grid;
                grid-template-columns: auto auto auto;
                gap: 15px;

                i {
                    font-size: 2.5em;
                }
            }

            &__button {
                &__redirect-button {
                    border: 3px abstracts.$orange-bright solid;
                    text-decoration: none;
                    background-color: abstracts.$grey300;
                    padding: 15px 50px;
                    color: abstracts.$orange-bright;
                    transition: all 0.1s ease-in-out;

                    @media (max-width: 600px) {
                        padding: 10px 30px;
                        font-size: small;
                    }

                    &:hover {
                        background-color: abstracts.$orange-bright;
                        color: abstracts.$white;
                        transition: all 0.1s ease-in-out;
                    }
                }
            }
        }
    }

    @media (max-width: 1320px) {
        flex-flow: column-reverse;
        height: 400px;
        width: 100%;

        &__image {
            width: 100%;
            height: 50%;
        }

        &__info {
            width: 100%;
            height: 50%;
            padding: 10px;
        }
    }

    @media (max-width: 1600px) {
        .card {
            &__info {
                font-size: small;

                &__footer {
                    padding-inline-end: 5%;
                }
            }

            &_image {
            }
        }

        @media (max-width: 1320px) {
            .card {
                width: 100%;
                grid-template-rows: none !important;

                grid-template-columns: repeat(1, 1fr);

                &__info {
                    &__footer {
                        padding-inline-end: 5%;
                    }
                }

                &_image {
                }
            }
        }
    }
}
