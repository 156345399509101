@use 'abstracts';
@use 'layouts';
@use 'animation';
@use 'pages';

*{
  margin: 0px;
  
}
body{
  background-color: abstracts.$grey100;
  

}
.App {
  text-align: center;
  //min-height: 100vh;
  //height: 200vh;
}


//
//$orange: invert(70%) sepia(66%) saturate(3317%) hue-rotate(320deg) brightness(101%) contrast(94%);

