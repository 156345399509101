@use "../abstracts";

.contact-page {
    //position: absolute;
    //background-color: aqua;
    //display: grid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.contact-title {
    text-align: center;
    //position: relative;
    display: flex;
    justify-content: center;
    font-family: abstracts.$arabic2;
    user-select: none;
    height: fit-content;
    margin-bottom: 100px;
    //top: -100px;

    &__title {
        font-size: 40px;
        color: abstracts.$white;
        z-index: 9999;
    }

    &__shadow {
        font-size: 80px;
        //bottom: -90px;
        transform: translateY(-30px);
        color: abstracts.$grey200;
        opacity: 0.3;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: absolute;
    }

    //margin-bottom: 40px;
}

.contact-container {
    background-color: abstracts.$white;
    //box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    //width: 400px;
    //height: auto;
    //display: grid;
    //justify-content: ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 600px;

    &__email {
        letter-spacing: 1px;
        color: abstracts.$white;
        background-color: abstracts.$grey200;
        height: 50px;
        box-shadow:
            rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
            rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
        //box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__phone {
        color: abstracts.$white;
        background-color: abstracts.$grey100;
        height: 50px;
        box-shadow:
            rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
            rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
        //box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .contact-form {
        //box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
        box-shadow:
            rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
            rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
        background-color: abstracts.$grey300;
        border-top: 10px ridge abstracts.$orange-bright;
        //background-color: rgb(117, 117, 117);
        //width: 500px;
        height: 300px;
        display: grid;
        grid-template-columns: auto;
        padding: 20px 40px;
        justify-content: start;

        input {
            //width: 100%;
            color: aliceblue;
            padding-right: 10px;
            background-color: abstracts.$grey200;
            border: none;
            //background-color: transparent;
            border-bottom: 2px solid abstracts.$orange-dark;
            border-right: 2px solid abstracts.$orange-dark;
            border-radius: 5px;
            margin-bottom: 4px;
            font-family: abstracts.$arabic2;
        }

        textarea {
            //background-color: transparent;
            background-color: abstracts.$grey200;
            color: aliceblue;
            padding-right: 10px;
            //border: 2px solid abstracts.$orange-dark;
            border: none;
            border-bottom: 2px solid abstracts.$orange-dark;
            border-right: 2px solid abstracts.$orange-dark;
            font-family: abstracts.$arabic2;
            border-radius: 5px;
            height: 140px;
        }

        .error {
            font-family: abstracts.$arabic2;
            font-size: small;
            position: relative;
            top: -5px;
            height: 11px;
            color: rgb(253, 101, 101);
            align-items: flex-start;
        }

        div {
            height: 20px;
        }

        input[type="submit"] {
            cursor: pointer;
            font-family: abstracts.$arabic2;
            width: 140px;
            text-decoration: none;
            color: abstracts.$white;
            padding: 10px 20px;
            border-radius: 4px;
            border: 2px solid abstracts.$orange-bright;
            background-color: abstracts.$orange-bright;
            transition: all 0.1s ease-in-out;

            &:hover {
                transition: all 0.1s ease-in-out;
                background-color: initial;
                color: abstracts.$orange-bright;
            }
        }
    }
}

.form-success {
    background-color: abstracts.$grey300;
    font-family: abstracts.$arabic2;
    color: abstracts.$orange-bright;
    display: flex;
    align-items: center;
    padding: 30px;
}

@media (max-width: 1400px) {
    .contact-container {
        width: 500px;
        //background-color: aliceblue;
    }

    @media (max-width: 800px) {
        .contact-container {
            width: 500px;
            //background-color: aliceblue;
        }
        @media (max-width: 500px) {
            .contact-title {
                margin-bottom: 20px;
            }
            .contact-container {
                width: 100%;
                //background-color: aliceblue;
            }
        }
    }
}
