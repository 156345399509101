@use "./../abstracts";

.backgroundimage {
    z-index: 0.1;
    position: absolute;
    display: inline-block;
    //bottom: 50%;
    width: 100%;
    height: 100vh;
    left: 0%;
    //transform: rotate(30deg);
    //filter: abstracts.$orange-svg;
    filter: abstracts.$orange-svg;
    opacity: 0.2;
    background-image: url("./../../assets/AssetBackground.svg");
    background-position: -50px -70px;
    background-repeat: no-repeat;
    background-size: 105vmax;
}

.backgroundfilter {
    z-index: 0.1;
    background: rgb(34, 193, 195);
    background: linear-gradient(180deg, rgba(34, 193, 195, 0) 0%, abstracts.$grey100 30%);
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100vh;
    left: 0%;
    margin: 0px;
    padding: 0px;
}

.IntroductionPage {
    background-color: abstracts.$grey100;
    width: 100%;
    //border: 10px solid black;
    height: 100vh;
    z-index: -99999;
}

.nav-social {
    position: absolute;
    top: 30%;
    bottom: 70%;
    border: 0px solid black;
    height: 400px;
    width: 100%;
    position: fixed;
    z-index: 99999;
    pointer-events: none;
}

.interface {
    z-index: 1;
    display: inline-block;
    position: relative;
    //left: 20%;
    top: 30%;
    display: grid;
    grid-template-columns: 50% 50%;

    justify-content: space-around;
    align-items: center;
    padding: 0 15%;
    //width:80%;
}
@media (max-width: 1070px) {
    .interface {
        padding: 0 10%;
    }
    @media (max-width: 900px) {
        .interface {
            grid-template-columns: auto;
            justify-content: center;
        }
        .callToAction {
            margin-top: 40px;
        }
    }

    @media (max-width: 370px) {
        .maintext {
            h4 {
                font-size: small;
            }
            h1 {
                font-size: large;
            }
            p {
                font-size: small;
            }
        }
        .interface {
            //font-size: small;
        }
    }
}
.maintext {
    //margin-left: 20%;
}
.callToAction {
    margin-right: 0%;
    justify-self: center;
}

.callToAction-mobile {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    &__button {
        text-decoration: none;
        color: abstracts.$orange-bright;
        border: 2px solid abstracts.$orange-bright;
        padding: 2px 20px;

        &:hover {
            background-color: abstracts.$orange-bright;
            color: abstracts.$white;
        }
    }
    &__social {
        color: abstracts.$white;
    }
}
